<template>
  <div class="warpbg">
    <div class="user_header">
      <div v-if="!isLogin" class="user_login">您好，请<span @click="handleShowLogin">【登录】</span></div>
      <div v-if="isLogin" class="user_logout" >欢迎您，{{ account }} <span @click="handleLogout">【退出】</span></div>
    </div>
    <div class="bouns_line"></div>
    <!-- 签到 -->
    <div class="frame_bonus">
      <div class="item_bonus" v-for="(item, index) in bonusList" :key="index">
        <img @click="userCheckIn(item.state, item.today)" class="package_bonus" 
          :src="item.state | filterbonusImgState(item.today, item.day)" alt="">
        <div class="bonus_state_text">{{  item.state | filterbonusState(item.today, item.tomorrow, item.day) }}</div>
      </div>
    </div>
    <!-- 签到奖励 -->
    <div class="frame_bonus_prop" v-show="showBonusProp">
      <div class="prop_content">{{ bonusPropName }}</div>
      <div class="btn_bonus_confirm" @click="showBonusProp = false"></div>
    </div>

    <!-- 充值档 -->
    <div class="recharge_level_line"></div>
    <!-- 充值档图标 -->
    <div class="frame_recharge_level">
      <div v-for="(list_item, list_index) in levelList" :key="list_index" class="recharge_level_list" 
        :class="{ 'recharge_level_list_l': list_item % 2 === 0, 'recharge_level_list_r': list_item % 2 === 1, 'recharge_level_list_last': list_index === 1 }">
        <img v-for="(item, index) in rechargeLevelList.slice(5*list_item, 5*(list_item+1))" 
          :key="index" :src="item.money | filterRechargeLevelState" alt="" class="recharge_level_img" 
          :class="{ 'recharge_level_img_last': (index === 4 && (list_item % 2 === 0)) || index === 0 && (list_item % 2 === 1) }"
          @click="handleShowTag(list_index, index)" >
      </div>
    </div>

    <!-- 充值档金额显示 -->
    <div class="frame_recharge_level_amount_0">
      <div class=" recharge_level_amount_list_l" >
        <div v-for="(item, index) in rechargeLevelList.slice(5*0, 5*(0+1))" 
          :key="index" class="recharge_level_amount_text">{{ item.money }}</div>
      </div>
    </div>

    <div class="frame_recharge_level_amount_1">
      <div class=" recharge_level_amount_list_r" >
        <div v-for="(item, index) in rechargeLevelList.slice(5*1, 5*(1+1))" 
          :key="index" class="recharge_level_amount_text">{{ item.money }}</div>
      </div>
    </div>

    <!-- <div class="frame_recharge_level_amount_1">
      <div v-for="(list_item, list_index) in levelList" :key="list_index" class="recharge_level_amount_list" 
        :class="{ 'recharge_level_amount_list_l': list_item % 2 === 0, 'recharge_level_amount_list_r': list_item % 2 === 1, 'recharge_level_list_last': list_index === 1 }">
        <div v-for="(item, index) in rechargeLevelList.slice(5*list_item, 5*(list_item+1))" 
          :key="index" class="recharge_level_amount_text">{{ item.money }}</div>
      </div>
    </div> -->


    <!-- 充值档金额图 -->
    <div class="frame_recharge_level_tag_0">
      <div class="recharge_level_tag_list">
        <div :class="{ 'recharge_level_item_show': showRechargeLevelTag[0][index], 'recharge_level_item_hidden': !showRechargeLevelTag[0][index] }" v-for="(item, index) in rechargeLevelList.slice(5*0, 5*(0+1))" 
          :key="index" class="recharge_level_item" @click="handleHiddenTag">
          <img class="draw_times_prop" :src="require('@/assets/activity/drawCoupon/dev/cell_coupon_img.png')" alt="">
          <div class="draw_times_prop_text">抽奖券 × {{ item.times }}</div>
        </div>
      </div>
    </div>
    <div class="frame_recharge_level_tag_1">
      <div class="recharge_level_tag_list">
        <div :class="{ 'recharge_level_item_show': showRechargeLevelTag[1][index], 'recharge_level_item_hidden': !showRechargeLevelTag[1][index] }" v-for="(item, index) in rechargeLevelList.slice(5*1, 5*(1+1))" 
          :key="index" class="recharge_level_item_r" @click="handleHiddenTag">
          <img class="draw_times_prop" :src="require('@/assets/activity/drawCoupon/dev/cell_coupon_img.png')" alt="">
          <div class="draw_times_prop_text">抽奖券 × {{ item.times }}</div>
        </div>
      </div>
    </div>


    <!-- 抽奖 -->
    <div class="frame_props">
      <!-- 顶部 -->
        <div class="box_props_top">
          <div class="cell_prop_h" :class="{ 'cell_prop_h_last': index===2 }" v-for="(item, index) in propList.slice(0, 3)" :key="index">
            <img class="prop_img" :src="item.prop_img" alt="">
          </div>
        </div>

        <!-- 中部 -->
        <div class="box_props_mid">
          <div class="box_props_mid_left">
            <div class="cell_prop_v" :class="{ 'cell_prop_v_last': index===1 }" v-for="(item, index) in propList.slice(8, 10).reverse()" :key="index">
              <img class="prop_img" :src="item.prop_img" alt="">
            </div>
          </div>
          <div class="box_redpackage">
            <div class="btn_draw" @click="handleDraw"></div>
            <div class="cell_draw_count">
              <div class="cell_coupon_img"></div>
              <div class="cell_draw_count_text"> : {{ drawTimes }}</div>
            </div>
            <div class="btn_refresh_count" @click="handleRefresh"></div>
          </div>
          <div class="box_prop_mid_right">
            <div class="cell_prop_v" :class="{ 'cell_prop_v_last': index===1 }" v-for="(item, index) in propList.slice(3, 5)" :key="index">
              <img class="prop_img" :src="item.prop_img" alt="">
            </div>
          </div>
        </div>

        <!-- 底部 -->
        <div class="box_props_bottom">
          <div class="cell_prop_h" :class="{ 'cell_prop_h_last': index===2 }" v-for="(item, index) in propList.slice(5, 8).reverse()" :key="index">
            <img class="prop_img" :src="item.prop_img" alt="">
          </div>
        </div>
    </div>

    <!-- 抽奖奖励 -->
    <div class="frame_draw_prop" v-show="showDrawProp">
      <img class="prop_content" :src="drawPropImg" alt="">
      <div class="btn_draw_confirm" @click="showDrawProp = false"></div>
    </div>

    <!-- 用户获得券列表 -->
    <div class="frame_user_coupon">
      <div v-for="(item, index) in userCouponList" :key="index" class="user_coupon_item">
        <img class="cell_prop_img" :src="item.prop_img" alt="">
        <img @click="couponToUse(item.used_at)" class="cell_use_state" :src="item.used_at | filterUserCouponState" alt="">
      </div>
    </div>

    <div class="frame_desc">
      <div v-html="desc" class="box_desc_text"></div>
    </div>

    <user-login
      :show="loginShow"
      :logout="logout"
      @close="handleCloseLogin"
      @login-success="handleLoginSuccess"
      @logout-success="handleLogoutSuccess"
      @input="handleLoginInput"
     />
  </div>
</template>

<script>
import UserLogin  from "@/components/UserLogin"
import { checkAuth, getUsername } from "@/utils/auth"
import { drawCoupon, draw, refresh, checkIn } from '@/api/drawCoupon'

let userRecharge = 0

export default {
  components: {
    userLogin: UserLogin
  },
  filters: {
    filterbonusImgState(state, today, day) {
      // state: 0 未领, 1 已领
      
      if (day === 5 && state === 0) {
        return require("@/assets/activity/drawCoupon/dev/box_bonus_last.png")
      } else if (day === 5 && state === 1) {
        return require("@/assets/activity/drawCoupon/dev/box_bonus_last_disable.png")
      }
      if (state === 0 && today === 1) {
        return require("@/assets/activity/drawCoupon/dev/today_bonus_able.png")
      } else if (state === 1) {
        return require("@/assets/activity/drawCoupon/dev/box_bonus_disable.png")
      } else if (state === 0) {
        return require("@/assets/activity/drawCoupon/dev/box_bonus.png")
      }
    },
    filterbonusState(state, today, tomorrow, day) {
      if (day === 5) {
        return '第五天大奖'
      }
      if (state === 0 && today === 1) {
        return '今日可领'
      } else if (tomorrow === 1) {
        return '明日可领'
      } else if (state === 1 && today === 1) {
        return '今日已领'
      } else if (state === 0) {
        return '未领'
      } else if (state === 1) {
        return '已领'
      }
    },
    filterUserCouponState(used_at) {
      if (used_at) {
        return require('@/assets/activity/drawCoupon/dev/user_coupon_used.png')
      } else {
        return require('@/assets/activity/drawCoupon/dev/user_coupon_to_use.png')
      }
    },
    filterRechargeLevelState(money) {
      if (userRecharge >= money) {
        return require('@/assets/activity/drawCoupon/dev/recharge_level_able.png')
      } else {
        return require('@/assets/activity/drawCoupon/dev/recharge_level_disable.png')
      }
    }
  },
  data() {
    return {
      isLogin: false,
      loginShow: false,
      account: undefined,
      logout: false,
      numberId: 2,
      bonusList: [],
      propList: [],
      rechargeLevelList: [],
      levelList: [],
      // userRecharge: 0,
      drawTimes: 0,
      drawPropImg: undefined,
      desc: undefined,
      userCouponList: [],
      showBonusProp: false,
      bonusPropName: undefined,
      showDrawProp: false,
      showRechargeLevelTag: []
    }
  },
  created() {
    this.initView()
    this.initUser()
    this.getIndex()
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";
    },
    getIndex() {
      drawCoupon({ number_id: this.numberId }).then(response => {
        this.bonusList = response.checkInList
        this.propList = response.props
        this.rechargeLevelList = response.activity.recharge_level
        userRecharge = response.user_recharge
        this.drawTimes = response.draw_times
        this.desc = '<p>' + response.activity.desc.replace(/\n/g, '</p><p>') + '</p>'
        const levelList = Math.ceil(this.rechargeLevelList.length / 5)
        this.levelList = []
        this.showRechargeLevelTag = []
        for (let i = 0; i < levelList; i++ ) {
          this.levelList.push(i)
          this.showRechargeLevelTag[i] = []
        }
        console.log(this.levelList)
        if (response.userCoupons) {
          this.userCouponList = response.userCoupons
          console.log(this.userCouponList)
        }
      })
    },
    checkLogin() {
      let loginState = false
      if (!this.isLogin) {
        this.loginShow = true
      } else {
        loginState = true
      }
      return loginState
    },
    initUser() {
      // 登录状态
      this.isLogin = checkAuth()
      if (this.isLogin) {
        this.account = getUsername()
        console.log(this.account)
      }
    },
    handleShowLogin() {
      this.loginShow = true
    },
    handleLogout() {
      this.logout = true
    },
    handleCloseLogin() {
      this.loginShow = false
    },
    handleLoginSuccess(user) {
      this.isLogin = true
      this.logout = false
      this.account = user.account
      this.$toast.success('登录成功')
      location.reload()
    },
    handleLogoutSuccess() {
      this.logout = false
      this.isLogin = false
      this.$toast.success('退出成功')
      location.reload()
    },
    handleLoginInput(e) {
      if (e === false) {
        this.loginShow = false
      }
    },
    // 抽
    handleDraw() {
      if (this.checkLogin()) {
        draw({ number_id: this.numberId }).then(response => {
          this.drawTimes--
          this.showDrawProp = true
          this.drawPropImg = response.prop_img
          this.userCouponList.unshift({ used_at: null, prop_img: response.prop_img })
        })
      }
    },
    // 刷新
    handleRefresh() {
      refresh({ number_id: this.numberId }).then(response => {
        this.drawTimes = response.draw_times
        userRecharge = response.user_recharge
        this.$toast.success('刷新成功')
      })
    },
    // 签到
    userCheckIn(state, today) {
      if (this.checkLogin()) {
        // 可签
        if (state === 0 && today === 1) {
          checkIn({ number_id: this.numberId }).then(response => {
            this.showBonusProp = true
            this.bonusList = response.checkInList
            console.log(this.bonusList)
            this.bonusPropName = ''
            let bonusPropArr = []
            if (response.prop.times > 0) {
              this.drawTimes += response.prop.times
              bonusPropArr.push('抽奖券 × ' + response.prop.times)
            }
            if (response.prop.discount > 0) {
              bonusPropArr.push(response.prop.prop_name)
              this.userCouponList.unshift({ used_at: null, prop_img: response.prop.prop_img })
            }
            this.bonusPropName = bonusPropArr.join(', ')
          })
        } else {
          console.log('不可签')
        }
      }
    },
    couponToUse(used_at) {
      if (!used_at) {
        this.$router.push({
          path: "/credit"
        })
      }
    },
    // 显示充值档抽奖券
    handleShowTag(list_index, index) {
      console.log(list_index, index)
      let arr = [[], []]
      arr.forEach((el) => {
        for(let i = 0; i <= 4; i++) {
          el.push(false)
        }
      })
      arr[list_index][index] = true
      this.showRechargeLevelTag = arr
      console.log(this.showRechargeLevelTag[list_index][index])
    },
    handleHiddenTag() {
      let arr = [[], []]
      arr.forEach((el) => {
        for(let i = 0; i <= 4; i++) {
          el.push(false)
        }
      })
      this.showRechargeLevelTag = arr
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.warpbg {
  height: 37rem;
  width: 100%;
  background: url('../../assets/activity/drawCoupon/dev/bg.jpg') top center no-repeat;
  background-size: contain;
  position: relative;
}
.user_header {
  position:absolute;
  right: 0.05rem;
  top: 0.22rem;
  font-size: 0.2rem;
  color: white;
  line-height: 0.2rem;
  text-shadow: 0 0 0.2rem black;
}
.bouns_line {
  width: 5.26rem;
  height: 0.04rem;
  top: 7.58rem;
  left: 1.19rem;
  background: url('../../assets/activity/drawCoupon/dev/bonus_line.png') top center no-repeat;
  background-size: cover;
  position: absolute;
}
/* 签到奖励 */
.frame_bonus_prop {
  top: 6.27rem;
  left: 0.52rem;
  width: 6.47rem;
  height: 2.81rem;
  background: url('../../assets/activity/drawCoupon/dev/bonus_prop_bg.png') top center no-repeat;
  background-size: contain;
  position: absolute;
}
.frame_bonus_prop .prop_content {
  width: 5.40rem;
  height: 1.16rem;
  font-size: 0.3rem;
  color: white;
  margin-top: 0.63rem;
  margin-left: 0.39rem;
  padding: 0.14rem;
}
.frame_bonus_prop .btn_bonus_confirm {
  width: 1.80rem;
  height: 0.56rem;
  margin: 0 auto;
  margin-top: 0.08rem;
  background: url('../../assets/activity/drawCoupon/dev/btn_confirm.png') top center no-repeat;
  background-size: contain;
  position:relative;
}
.frame_bonus {
  height: auto;
  width: auto;
  position:absolute;
  margin-top: 7.0rem;
  float: left;
  margin-left: 0.4rem;
}
.item_bonus {
  height: auto;
  width: 1.30rem;
  float: left;
  /* margin-right: 0.66rem; */
}
.bonus_state_text {
  font-size: 0.2rem;
  color: white;
  text-align: center;
}
.package_bonus {
  display: block;
  width: 0.92rem;
  height: 1.08rem;
  margin: 0 auto;
}
/* .package_normal {
  display: block;
  width: 0.92rem;
  height: 1.08rem;
  margin: 0 auto;
}
.package_today_able {
  display: block;
  width: 0.92rem;
  height: 1.08rem;
  margin: 0 auto;
}
.package_disable {
  display: block;
  width: 0.92rem;
  height: 1.08rem;
  margin: 0 auto;
}
.package_last {
  display: block;
  width: 0.92rem;
  height: 1.08rem;
  margin: 0 auto;
} */

/* 充值档 */
.recharge_level_line {
  position:absolute;
  top: 11.31rem;
  left: 0.42rem;
  width: 6.65rem;
  height: 1.36rem;
  background: url('../../assets/activity/drawCoupon/dev/recharge_level_line.png') top center no-repeat;
  background-size: contain;
}
.frame_recharge_level {
  position:absolute;
  top: 11.05rem;
  left: 0.25rem;
  width: 5.80rem;
  /* height: 1.88rem; */
}
.recharge_level_list {
  /* width: 5.66rem; */
  height: 0.56rem;
  margin-bottom: 0.76rem;
  font-size: 0;
}
.recharge_level_list_l {
  float: left;
}
.recharge_level_list_r {
  float:right;
}
.recharge_level_list_last {
  margin-bottom: 0;
}
.recharge_level_img {
  display: block;
  width: 0.56rem;
  height: 0.56rem;
  margin-right: 0.75rem;
  float:inherit;
}
.recharge_level_img_last {
  margin-right: 0;
}
/* 充值档金额数额显示 */
.frame_recharge_level_amount_0 {
  position:absolute;
  top: 11.7rem;
}
.frame_recharge_level_amount_1 {
  position:absolute;
  top: 13.02rem;
}
.recharge_level_amount_list_l {
  float: left;
}
.recharge_level_amount_list_r {
  float: right;
  margin-right: 0.95em;
}
.recharge_level_amount_text {
  font-size: 0.25rem;
  color: #fed100;
  float:inherit;
  width: 1.06rem;
  text-align: center;
  margin-right: 0.25rem;
}

/* 充值档金额图 */
.frame_recharge_level_tag_0 {
  position:absolute;
  width: 6.9rem;
  top: 10.32rem;
  left: 0.22rem;
}
.frame_recharge_level_tag_1 {
  position:absolute;
  width: 6.9rem;
  top: 11.64rem;
  left: 0.22rem;
}
.recharge_level_tag_list {
  height: 0.83rem;
  width: 100%;
  /* margin-bottom: 0.5rem */
}
.recharge_level_item {
  float: left;
  width: 1.79rem;
  height: 0.83rem;
  background: url('../../assets/activity/drawCoupon/dev/draw_times_tag.png') top center no-repeat;
  background-size: contain;
  margin-right: -0.5rem;
}
.recharge_level_item_r {
  width: 1.79rem;
  height: 0.83rem;
  background: url('../../assets/activity/drawCoupon/dev/draw_times_tag.png') top center no-repeat;
  background-size: contain;
  margin-left: -0.5rem;
  float:right;
}
.draw_times_prop {
  float: left;
  display: block;
  width: 0.77rem;
  height: 0.55rem;
  margin-top: 0.06rem;
  margin-left: 0.09rem;
}
.draw_times_prop_text {
  float: left;
  font-size: 0.28rem;
  color: white;
  width: 0.93rem;
}
.recharge_level_item_show {
  visibility:visible;
}
.recharge_level_item_hidden {
  visibility:hidden
}

/* 抽奖 */
.frame_props {
  width: 6.91rem;
  height: 5.47rem;
  position:absolute;
  top: 13.9rem;
  margin-left: 0.29rem;
}
.box_props_top, .box_props_bottom {
  width: 100%;
  height: 0.89rem;
}
.cell_prop_h {
  float: left;
  width: 1.89rem;
  height: 0.89rem;
  margin-right: 0.62rem;
}
.cell_prop_h_last {
  margin-right: 0;
}
.cell_prop_h .prop_img, .cell_prop_v .prop_img {
  display: block;
  width: 1.5rem;
  height: 0.64rem;
  margin: auto;
  margin-top: 0.12rem;
}
.box_props_mid {
  width: 100%;
  height: 3.7rem;
}
.box_props_mid_left, .box_prop_mid_right {
  float: left;
  width: 1.89rem;
  margin-top: 0.64rem;
}
.cell_prop_v {
  width: 1.89rem;
  height: 0.89rem;
  margin-bottom: 0.64rem;
}
.cell_prop_v_last {
  margin-bottom: 0;
}
.box_redpackage {
  float: left;
  width: 3.13rem;
  height: 3.7rem;
}
.btn_draw {
  width: 1.88rem;
  height: 1.88rem;
  background: url('../../assets/activity/drawCoupon/dev/btn_draw_bg.png') top center no-repeat;
  background-size: contain;
  position: relative;
  margin: 0 auto;
  margin-top: 0.25rem;
}
.cell_draw_count {
  height: 0.74rem;
  width: 100%;
}
.cell_coupon_img {
  float: left;
  width: 0.77rem;
  height: 0.55rem;
  background: url('../../assets/activity/drawCoupon/dev/cell_coupon_img.png') top center no-repeat;
  background-size: contain;
  position: relative;
  margin-left: 0.74rem;
  margin-top: 0.07rem;
}
.cell_draw_count_text {
  float: left;
  font-size: 0.4rem;
  line-height: 0.74rem;
  color: white;
}
.btn_refresh_count {
  width: 1.86rem;
  height: 0.55rem;
  background: url('../../assets/activity/drawCoupon/dev/btn_refresh.png') top center no-repeat;
  background-size: contain;
  position: relative;
  margin: 0 auto;
}
.frame_draw_prop {
  top: 15.4rem;
  left: 0.52rem;
  width: 6.47rem;
  height: 2.81rem;
  background: url('../../assets/activity/drawCoupon/dev/draw_prop_bg.png') top center no-repeat;
  background-size: contain;
  position: absolute;
}
.frame_draw_prop .prop_content {
  display: block;
  width: 1.5rem;
  height: 0.64rem;
  margin: 0 auto;
  margin-top: 1rem;
}
.frame_draw_prop .btn_draw_confirm {
  width: 1.80rem;
  height: 0.56rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: url('../../assets/activity/drawCoupon/dev/btn_confirm.png') top center no-repeat;
  background-size: contain;
  position:relative;
}
/* 用户券 */
.frame_user_coupon {
  width: 6.54rem;
  height: 3.48rem;
  background: url('../../assets/activity/drawCoupon/dev/user_coupon_bg.png') top center no-repeat;
  background-size: contain;
  position:absolute;
  top: 21.42rem;
  left: 0.48rem;
  overflow-y: scroll;
}
.user_coupon_item {
  width: 4.69rem;
  height: 0.64rem;
  margin-top: 0.34rem;
  margin-left: 0.8rem;
  margin-bottom: 0.4rem;
}
.cell_prop_img {
  display: block;
  float: left;
  width: 1.5rem;
  height: 0.64rem;
}
.cell_use_state {
  display: block;
  margin-left: 1.3rem;
  float: left;
  width: 1.89rem;
  height: 0.64rem;
}

.frame_desc {
  position:absolute;
  top: 26.3rem;
  left: 0.2rem;
  font-size: 0.2rem;
  color: white;
}
.box_desc_text p {
  margin: 0.2rem auto;
}
</style>