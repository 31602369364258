import api from '@/utils/api'

export function drawCoupon(params) {
  return api.get('activity/v1/drawCoupon', { params:params })
}

export function refresh(params) {
  return api.get('activity/v1/drawCoupon/refresh', { params:params })
}

export function draw(params) {
  return api.post('activity/v1/drawCoupon/draw', params)
}

export function checkIn(params) {
  return api.post('activity/v1/drawCoupon/checkIn', params)
}
