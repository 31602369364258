import api from '@/utils/api'

export function login(params) {
    let url = 'wx/v1/login'
    return api.post(url, params)
}

export function logout() {
    return api.get('wx/v1/logout')
}

export function webLogin(params) {
  let url = 'user/v1/login'
  return api.post(url, params)
}

export function webLogout() {
  let url = 'user/v1/logout'
  return api.get(url)
}
